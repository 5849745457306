<template>
  <Bar
    v-if="loaded"
    :data="chartData"
    :options="chartOptions"
    :aria-label="'Bar chart showing ' + clonedChartConfig.title"
    :plugins="[chartDataLabels]"
  />
</template>
<script>
import { Bar } from "vue-chartjs";
import {
  Chart,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: "BarChart",
  components: { Bar },
  data: () => ({
    prefix: null,
    suffix: null,
    loaded: false,
    options: null,
    clonedChartConfig: null,
    chartDataLabels: ChartDataLabels,
  }),
  computed: {
    chartData() {
      return this.clonedChartConfig;
    },
    chartOptions() {
      return this.options;
    },
  },
  props: {
    chartConfig: null,
    stacked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.clonedChartConfig = JSON.parse(JSON.stringify(this.chartConfig));
    this.lineBreakLables();
    this.buildChart();
  },
  methods: {
    // this breaks up the chart labels onto multiple lines if they are too long
    lineBreakLables() {
      var labelThresHold = this.stacked ? 2 : 2;
      var maxWordsInARow =
        this.clonedChartConfig.labels.toString().length < 170 ? 3 : 1;
      // if there are more than the labels threshold
      if (
        this.clonedChartConfig.labels.length > labelThresHold ||
        this.clonedChartConfig.labels.toString().length > 140
      ) {
        for (var i = 0; i < this.clonedChartConfig.labels.length; i++) {
          if (this.clonedChartConfig.labels[i].length > 25) {
            let explode = this.clonedChartConfig.labels[i].split(" ");
            let labelArray = [];
            labelArray.push("");
            let counter = 0;
            for (var j = 0; j < explode.length; j++) {
              if (j && j % maxWordsInARow === 0) {
                counter++;
                labelArray.push("");
              }
              labelArray[counter] = labelArray[counter] + " " + explode[j];
            }
            this.clonedChartConfig.labels[i] = labelArray;
          }
        }
      }
    },
    convertStringToNumber(input) {
      // If the input is a number, convert it to a string first
      if (typeof input === "number") {
        input = input.toString();
      }

      // Now safely call match() on the string
      let cleanStr = input.match(/-?\d+(\.\d+)?/);

      // If a valid number is found, convert it to a float
      if (cleanStr) {
        return parseFloat(cleanStr[0]);
      } else {
        return NaN;
      }
    },
    sortPrefixesAndSuffixes() {
      // spoof a negative number
      // this.clonedChartConfig.datasets[0].data[1] = -123456;
      // these charts only like numbers, so we have to stript out any Prefixes & Suffixes in the data and add concatinate them to the the labels and tooltips later on
      for (var i = 0; i < this.clonedChartConfig.datasets.length; i++) {
        // only do this the once
        if (
          typeof this.clonedChartConfig.datasets[i].prefixes === "undefined"
        ) {
          this.clonedChartConfig.datasets[i].prefixes = [];
          this.clonedChartConfig.datasets[i].suffixes = [];
          this.clonedChartConfig.datasets[i].numbers = [];
          for (
            var j = 0;
            j < this.clonedChartConfig.datasets[i].data.length;
            j++
          ) {
            // If there is data, then process prefix and suffix
            if (this.clonedChartConfig.datasets[i].data[j]) {
              this.clonedChartConfig.datasets[i].numbers.push(
                this.convertStringToNumber(
                  this.clonedChartConfig.datasets[i].data[j],
                ),
              );
              this.clonedChartConfig.datasets[i].data[j] = String(
                this.clonedChartConfig.datasets[i].data[j],
              );
              // Attempt to get the suffix
              var match =
                this.clonedChartConfig.datasets[i].data[j].match(/\D+$/);
              var newSuffix = match ? match[0] : "";
              this.suffix = newSuffix;

              // If the suffix has changed, report to user that there is mixed data
              if (this.suffix !== newSuffix) {
                this.$emit("data-error", this.clonedChartConfig.canvasID);
              }

              this.clonedChartConfig.datasets[i].suffixes.push(this.suffix);
              // Remove the suffix from the data
              this.clonedChartConfig.datasets[i].data[j] =
                this.clonedChartConfig.datasets[i].data[j].replace(/\D+$/, "");
              // Do similar to the above for the prefix
              this.clonedChartConfig.datasets[i].data[j] = String(
                this.clonedChartConfig.datasets[i].data[j],
              );
              match = this.clonedChartConfig.datasets[i].data[j].match(/^\D+/);
              this.prefix = match ? match[0] : "";
              this.clonedChartConfig.datasets[i].prefixes.push(this.prefix);
              // Remove the prefix from the data
              this.clonedChartConfig.datasets[i].data[j] =
                this.clonedChartConfig.datasets[i].data[j].replace(/^\D+/, "");
            } else {
              // No data so do behaviour for no prefix/suffix found
              this.clonedChartConfig.datasets[i].suffixes.push("");
              this.clonedChartConfig.datasets[i].prefixes.push("");
              // Alert the user
              this.$emit("data-error", this.clonedChartConfig.canvasID);
            }
            // convert back to number
            this.clonedChartConfig.datasets[i].data[j] =
              this.clonedChartConfig.datasets[i].numbers[j];
          }
        }
      }
    },
    buildChart() {
      this.sortPrefixesAndSuffixes();
      // Sum the data for each stacked bar chart to find the largest value
      var largestValue = 0;
      var currentStack = 0;
      // Loop through each comparator
      for (var i = 0; i < this.clonedChartConfig.labels.length; i++) {
        // Loop through each indicator
        currentStack = 0;
        for (var j = 0; j < this.clonedChartConfig.datasets.length; j++) {
          var value = parseFloat(this.clonedChartConfig.datasets[j].data[i]);
          if (!isNaN(value)) {
            currentStack += value;
          }
        }
        if (currentStack > largestValue) {
          largestValue = currentStack;
        }
      }
      // For stacked bar charts, do not go over 100 on the Y axis if the data doesn't go over 100
      var ticks = {
        beginAtZero: true,
        callback: (value) => {
          return this.prefix + value + this.suffix;
        },
      };
      // Round down to nearest 1% to account for summing rounding errors.
      largestValue = Math.floor(largestValue);
      if (largestValue <= 100) {
        if (this.stacked) {
          ticks.max = 100;
        }
      }
      this.options = {
        responsive: true,
        indexAxis: "y",
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: this.clonedChartConfig.title,
            font: {
              size: 18,
              style: "normal",
              lineHeight: 1.2,
            },
          },
          datalabels: {
            //chartjs-plugin-datalabels config
            color: (context) => {
              if (this.getChartValuePercentOfMax(context) > 15) {
                return "white";
              }
            },
            anchor: (context) => {
              //if bar is too small i.e. less than 15% of highest value - anchor label at top
              if (this.getChartValuePercentOfMax(context) < 15) {
                return "end";
              } else {
                return "center";
              }
            },
            align: (context) => {
              //if bar is too small i.e. less than 15% of highest value - offset label at top
              if (this.getChartValuePercentOfMax(context) < 15) {
                return "right";
              } else {
                return "center";
              }
            },
            formatter: function (value, context) {
              //add prefix/suffix to data value & round to 2dp
              let dataIndex = context.dataset.data.indexOf(value);
              let isInt = value % 1 === 0;
              let displayVal = isInt ? value : value.toFixed(2);
              return (
                context.dataset.prefixes[dataIndex] +
                displayVal +
                context.dataset.suffixes[dataIndex]
              );
            },
            font: (context) => {
              //set a dynamic label size
              let size = this.getChartLabelFontSize(context);
              return {
                size: size,
                weight: 600,
              };
            },
            display: (context) => {
              //hide the labels if they're too small to read
              let size = this.getChartLabelFontSize(context);
              if (size < 8) {
                return false;
              }
            },
          },
          tooltip: {
            enabled: false,
            callbacks: {
              title: (title) => {
                const name = this.clonedChartConfig.labels[title[0].dataIndex];

                return Array.isArray(name) ? name.join("") : name;
              },
              label: (tooltipItem) => {
                const { label: areaName } = tooltipItem.dataset;
                const { raw: value, datasetIndex, dataIndex } = tooltipItem;
                const dataset = this.clonedChartConfig.datasets[datasetIndex];
                const prefix = dataset.prefixes[dataIndex];
                const suffix = dataset.suffixes[dataIndex];

                return ` ${areaName}: ${prefix}${value}${suffix}`;
              },
            },
          },
        },
        legend: {
          labels: {
            fontSize: 16,
          },
        },
        scales: {
          x: {
            stacked: this.stacked,
            display: true,
            ticks: {
              font: {
                size: 14,
                style: "normal",
                lineHeight: 1.2,
              },
            },
          },
          y: {
            stacked: this.stacked,
            beginAtZero: false,
            display: true,
            title: {
              display: this.clonedChartConfig.chartYLabel ? true : false,
              text: this.clonedChartConfig.chartYLabel,
              font: {
                size: 16,
                style: "normal",
                lineHeight: 1.2,
              },
            },
            padding: { top: 30, left: 0, right: 0, bottom: 0 },
          },
        },
      };
      this.loaded = true;
    },
    //return the highest data value from given chart context
    getChartMaxValue(chartCtx) {
      let chartVals = [];
      chartCtx.chart.config.data.datasets.forEach((d) => {
        chartVals.push(...d["data"]);
      });
      return Math.max(...chartVals);
    },
    //return whether specific chart value as percentage of chart max value
    getChartValuePercentOfMax(chartCtx) {
      let maxValue = this.getChartMaxValue(chartCtx);
      let index = chartCtx.dataIndex; //index of data for context instance
      let value = chartCtx.dataset.data[index]; //value of datapoint for context instance

      return (value / maxValue) * 100;
    },
    //return the font size (px) for a given chart context
    getChartLabelFontSize(chartCtx) {
      //number of datasets for each bar group
      let datasetsPerBar = chartCtx.chart.config.data.datasets.length;
      //number of bar groups
      let BarsInChart = chartCtx.chart.config.data.datasets[0].data.length;

      /**
       *  abitrary calculation to create a dynamic pixel size
       *  e.g. max single character pixel size /
       *    number of bars in chart * chart height (where 1000px is 100%)
       */
      let size = Math.round(
        (512 / (datasetsPerBar * BarsInChart)) * (chartCtx.chart.height / 1000),
      );

      //assuming we don't want giant labels in basic charts - limit font size
      if (size > 25) {
        return 25;
      }

      return size;
    },
  },
  watch: {},
};
</script>

<style scoped></style>
