<template>
  <div>
    <v-expansion-panels v-model="isTabOpened" :disabled="isTabDisabled">
      <v-expansion-panel :value="true" id="discoveryToolMapCanvas">
        <v-expansion-panel-title class="expansionTitlePanel">
          <template v-slot:default>
            <v-row no-gutters style="align-items: center">
              <v-icon
                icon="mdi-information"
                color="primary"
                class="mr-3"
                @click.stop="indicatorDetailsDialogue = true"
                style="cursor: pointer; pointer-events: auto"
              />

              <div class="d-flex justify-start">
                {{
                  selectedIndicator?.indicator_name || "Loading indicator..."
                }}
              </div>
            </v-row>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          :style="{
            height: mapHeight + 'px',
            padding: 0,
          }"
          class="map-container"
        >
          <div
            :ref="`mapCanvas_${mapID}`"
            :id="`mapCanvas_${mapID}`"
            :class="`mapCanvas_${mapID}`"
            style="width: 100%; height: 100%"
          ></div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <IndicatorDetailsDialog
      v-model:mapAboutIndDialog="indicatorDetailsDialogue"
      :viewInfo="{
        indicatorInfo: selectedIndicator,
      }"
    >
      <!-- Quintiles -->
      <div class="indInfoHeader"><b>Quintiles</b></div>
      <v-card-text
        v-if="mapQuintiles.q1_min === 'loading'"
        class="loadingQuintiles indInfoText pl-0 pt-1"
      >
        <v-skeleton-loader v-for="n in 5" :key="n" type="text" width="30%" />
      </v-card-text>
      <v-card-actions v-else class="pl-0 pb-0">
        <!-- quintiles -->
        <div class="d-flex indInfoText">
          <MapQuintiles
            :viewInfo="{
              quintiles: mapQuintiles,
              indicatorInfo: selectedIndicator,
            }"
            :colourScheme="colourScheme"
          />
        </div>
      </v-card-actions>
    </IndicatorDetailsDialog>
  </div>
</template>

<script>
/* global google */

import { loadGoogleMaps } from "@/mixins/LoadGoogleMaps";
import { useDisplay } from "vuetify";
import { toRaw } from "vue";
import IndicatorDetailsDialog from "./IndicatorDetailsDialog.vue";
import MapQuintiles from "@/components/MapQuintiles.vue";

export default {
  name: "DiscoveryToolMap",
  components: {
    IndicatorDetailsDialog,
    MapQuintiles,
  },
  data: () => ({
    map: null,
    screenHeight: useDisplay().height,
    mapHeight: 100,
    center: { lat: 54.36, lng: -2.59 },
    zoom: 6,
    previousZoom: 6,
    polygonObjects: [],
    latestQuintileLevel: 99,
    latestIndicatorId: 0,
    polygonsWithDataset: null,
    hotspotFlag: "all",
    syncInProgress: false,
    isTabOpened: true,
    loadingMap: false,
    isTabDisabled: false,
    indicatorDetailsDialogue: false,
    mapQuintiles: { q1_min: "loading" },
    fetchingQuintiles: false,
    previousIndicatorId: null, // TODO: editing of the inidicators
    mouseFeature: null,
    hoveredAreaID: null,
  }),
  mounted() {
    // setTimeout(() => {
    this.mapSetUp();

    this.adjustMapHeight(3);
    // }, this.mapID * 1000);
  },
  props: {
    mapID: {
      type: Number,
      required: true,
    },
    viewInfo: {
      type: Object,
      required: true,
    },
    selectedIndicator: {
      required: true,
    },
    colourScheme: {
      required: true,
    },
    getLoadedDataForNewMap: {
      type: Function,
      required: true,
    },
    areaMouseOverInfoProp: {
      type: Object,
      required: true,
    },
  },
  computed: {
    areasOfInterest: {
      get() {
        return this.$store.state.areasOfInterest;
      },
      set(value) {
        this.$store.commit("setAreasOfInterest", value);
      },
    },
    areaMouseOverInfo: {
      get() {
        return this.areaMouseOverInfoProp;
      },
      set(value) {
        this.$emit("update:areaMouseOverInfoProp", value);
      },
    },
  },
  methods: {
    async mapSetUp(center = this.center, zoom = this.zoom) {
      return new Promise((resolve) => {
        loadGoogleMaps(center, zoom, `mapCanvas_${this.mapID}`).then(
          (loadedMap) => {
            this.map = loadedMap;

            // Add event listeners for zoom and center changes
            this.map.addListener(
              "zoom_changed",
              () => {
                if (!this.syncInProgress && !this.loadingMap) {
                  this.$emit("syncZoom", this.mapID, this.map.getZoom());
                  // console.log("zoom changed");

                  this.$emit("deBounce", { event: "zoom" });
                }
              },
              {
                passive: true,
              },
            );

            this.map.addListener(
              "center_changed",
              () => {
                if (!this.syncInProgress && !this.loadingMap) {
                  this.$emit("syncCenter", this.mapID, this.map.getCenter());
                  // console.log("center changed");

                  this.$emit("deBounce", { event: "drag" });
                }
              },
              { passive: true },
            );

            resolve();
          },
        );
      });
    },
    syncZoom(zoom) {
      this.syncInProgress = true;
      this.map.setZoom(zoom);
      this.syncInProgress = false;
    },
    syncCenter(center) {
      this.syncInProgress = true;
      this.map.setCenter(center);
      this.syncInProgress = false;
    },
    handlePanelChange() {
      this.$nextTick(() => {
        if (this.isTabOpened) {
          this.initializeMap();
        } else {
          this.clearMapData();
        }

        this.$emit("resizeTheMaps");
      });
    },
    // triggers when expansion panel is closed
    clearMapData() {
      this.polygonObjects = [];
      this.polygonsWithDataset = null;
      this.map.data.forEach((feature) => {
        this.map.data.remove(feature);
      });

      this.map = null;
    },
    // triggers when expansion panel is opened
    async initializeMap() {
      const data = this.getLoadedDataForNewMap(this.mapID);
      try {
        await this.mapSetUp(data.center, data.zoom);

        if (data.parentBoundaries) {
          this.displayParentAreaBoundaries(data.parentBoundaries, false);
        }

        // load areas of interest
        if (data.areaOfInterestPolygons.length) {
          data.areaOfInterestPolygons.forEach((feature) => {
            this.updatePolygonWithAreasOfInterest(
              feature.geometries,
              feature.visible,
            );
          });
        }

        this.getPolygonsWithDataset();
      } catch (error) {
        this.emit.emit("systemMessage", {
          title: "Error occured during rendering the map",
          message: "Please try again later",
          timeout: 4000,
          color: "warning",
        });
        console.error(error);
      }
    },
    adjustMapHeight(openedMapsCount) {
      const HEADER_HEIGHT = 48;
      const EXPANSION_PANEL_TITLES_HEIGHT = 47 * 3; // we have 3 expansion panels

      const isOnlyMapOpened = this.isTabOpened && openedMapsCount === 1;

      const availableHeight =
        this.screenHeight - (HEADER_HEIGHT + EXPANSION_PANEL_TITLES_HEIGHT);

      setTimeout(() => {
        this.mapHeight = isOnlyMapOpened
          ? availableHeight
          : availableHeight / openedMapsCount;
      }, this.mapID * 300);

      // if this is the only map opened, disable the tab as user shouldn't close the last visible map
      this.isTabDisabled = isOnlyMapOpened;
    },
    getMapBounds() {
      return {
        xmin: this.map.getBounds().getSouthWest().lng(),
        ymin: this.map.getBounds().getSouthWest().lat(),
        xmax: this.map.getBounds().getNorthEast().lng(),
        ymax: this.map.getBounds().getNorthEast().lat(),
      };
    },
    getCurrentZoomLevel() {
      return this.map.getZoom();
    },
    getCurrentCenter() {
      const center = this.map.getCenter();
      return {
        lat: center.lat(),
        lng: center.lng(),
      };
    },
    addDataLayerListeners() {
      // add listener to see if anything changes
      // mouse over to show the area & value
      this.map.data.addListener(
        "mouseover",
        function (event) {
          this.hoveredAreaID = event.feature.getProperty("area_id");

          // take a note of the feature clicked
          // this.mouseFeature = event.feature;

          this.$emit("syncHoverOverMap", this.hoveredAreaID);
        }.bind(this),
      );

      this.map.data.addListener(
        "mouseout",
        function () {
          this.$emit("syncHoverOverMap", this.hoveredAreaID, true);
        }.bind(this),
      );

      // click to add area to the areas of interest
      this.map.data.addListener(
        "click",
        async function (event) {
          if (!event.alreadyCalled_) {
            event.alreadyCalled_ = true;
            const areaID = event.feature.getProperty("area_id");

            // get the geometries of the area user clicked on
            const geometries = this.polygonsWithDataset.features.find(
              (f) => f.properties.area_id === areaID,
            );

            this.$emit("handleClickOnMap", areaID, geometries);
          }
        }.bind(this),
      );
    },
    async displayParentAreaBoundaries(response, zoomIn = true) {
      // return new Promise((resolve, refect) => {
      if (response.boundary) {
        const { Polygon } = await google.maps.importLibrary("maps");

        const boundary = JSON.parse(response.boundary);

        // build an array of polygons, can be one, or many for a multiplygon
        let polygons = [];

        if (boundary.type === "Polygon") {
          // Polygon
          const googleMapsPaths = boundary.coordinates.map((ring) =>
            ring.map((coordinate) => {
              return { lat: coordinate[1], lng: coordinate[0] };
            }),
          );

          polygons.push(
            new Polygon({
              map: this.map,
              paths: googleMapsPaths,
              fillOpacity: 0,
              strokeWeight: 4,
              strokeColor: response.boundary_colour,
              zIndex: 10,
              clickable: false,
            }),
          );
        } else {
          // `MultiPolygon`
          const multiCoordinates = boundary.coordinates;
          multiCoordinates.forEach((coords) => {
            const googleMapsPaths = coords.map((coord) => {
              return coord.map((subCoord) => {
                return { lat: subCoord[1], lng: subCoord[0] };
              });
            });

            polygons.push(
              new Polygon({
                map: this.map,
                paths: googleMapsPaths,
                fillOpacity: 0,
                strokeWeight: 4,
                strokeColor: response.boundary_colour,
                zIndex: 10,
                clickable: false,
              }),
            );
          });
        }

        if (zoomIn) {
          await this.zoomToParentAreas(polygons);
        }
      }
    },
    async zoomToParentAreas(polygons) {
      // gather up all the bounds of any loaded polys
      var bounds = new google.maps.LatLngBounds();

      // iterate over each polygon in this.polygonObjects and extend the bounds
      polygons.forEach(function (polygon) {
        polygon.getPath().forEach(function (latLng) {
          bounds.extend(latLng);
        });
      });

      // fit the map to the bounds to show all the polygons
      this.map.fitBounds(bounds);
    },
    async getPolygonsWithDataset() {
      this.emit.emit("systemBusy", true);

      // set indicator info for the backend
      let viewInfo = JSON.parse(JSON.stringify(this.viewInfo));
      viewInfo.indicatorInfo = this.selectedIndicator;
      viewInfo.viewportInfo.ind_id = this.selectedIndicator.id;

      // set the colours
      var colors = this.colourScheme;
      var color;

      this.$axios
        .post("/data-geometries", viewInfo.viewportInfo)
        .then(
          function (response) {
            // handle success
            this.polygonsWithDataset = response.data;

            let features = this.map.data;
            features.forEach(function (feature) {
              features.remove(feature);
            });
            if (this.polygonsWithDataset.features) {
              this.map.data.addGeoJson(this.polygonsWithDataset);
              this.addDataLayerListeners();
              this.map.data.setStyle(function (feature) {
                var quintile = feature.getProperty("quintile");
                switch (quintile) {
                  case 1:
                    color = "#" + colors[0];
                    break;
                  case 2:
                    color = "#" + colors[1];
                    break;
                  case 3:
                    color = "#" + colors[2];
                    break;
                  case 4:
                    color = "#" + colors[3];
                    break;
                  case 5:
                    color = "#" + colors[4];
                    break;
                  default:
                    color = "#" + colors[4];
                }
                return {
                  fillColor: color,
                  fillOpacity: 0.5,
                  strokeColor: "#FFFFFF",
                  strokeWeight: 0.5,
                };
              });
            }
            this.emit.emit("systemBusy", false);

            return true;
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Failed to load data",
              timeout: 4000,
              colour: "error",
            });
            console.error(error);
          }.bind(this),
        );
    },
    getDataCount() {
      this.fetchingQuintiles = true;

      // set indicator info for the backend
      let viewportInfo = this.viewInfo.viewportInfo;
      viewportInfo.ind_id = this.selectedIndicator.id;

      this.$axios
        .post("/get-data-count", viewportInfo)
        .then(
          function (response) {
            // handle success
            if (response.data > 0) {
              // call this for the information panel
              this.buildIndicatorQuintiles();
            } else {
              this.mapQuintiles.q1_min = null;
              this.fetchingQuintiles = false;
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Failed to load data",
              timeout: 4000,
              colour: "error",
            });
            console.error(error);
          }.bind(this),
        );
    },
    buildIndicatorQuintiles() {
      if (
        this.viewInfo.viewportInfo.dataLevel != this.latestQuintileLevel ||
        this.selectedIndicator.id != this.latestIndicatorId
      ) {
        this.mapQuintiles = {
          q1_min: "loading",
        };

        this.$axios
          .get(
            "/standard-data-quintile-range/" +
              this.selectedIndicator.id +
              "/" +
              this.viewInfo.viewportInfo.dataLevel +
              "/" +
              this.viewInfo.viewportInfo.hotspotFlag,
          )
          .then((response) => {
            // handle success
            this.mapQuintiles = response.data;
          })
          .catch(
            function (error) {
              // handle error
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Failed to build the quintiles",
                timeout: 5000,
                colour: "error",
              });
              console.error(error);
            }.bind(this),
          )
          .finally(() => {
            this.fetchingQuintiles = false;
          });

        // update our latest fetched quintile info
        this.latestQuintileLevel = this.viewInfo.viewportInfo.dataLevel;
        this.latestIndicatorId = this.selectedIndicator.id;
      }
    },
    async updatePolygonWithAreasOfInterest(feature, visible = true) {
      const { Polygon } = await google.maps.importLibrary("maps");

      const geometry = feature.geometry;
      const geometryType = geometry.type;
      const areaId = feature.properties.area_id;

      if (geometryType === "Polygon" || geometryType === "MultiPolygon") {
        if (geometryType === "Polygon") {
          // Polygon with possible holes
          const googleMapsPaths = geometry.coordinates.map((ring) =>
            ring.map((coord) => {
              return { lat: coord[1], lng: coord[0] };
            }),
          );

          const googleMapsPolygon = new Polygon({
            map: this.map,
            paths: googleMapsPaths,
            fillOpacity: 0,
            strokeWeight: 3,
            strokeColor: "#FF0000",
            zIndex: 10,
            clickable: false,
            visible: visible,
          });

          // add custom props
          googleMapsPolygon.area_id = areaId;
          this.polygonObjects.push(googleMapsPolygon);
        } else if (geometryType === "MultiPolygon") {
          // MultiPolygon
          const multiCoordinates = geometry.coordinates;
          multiCoordinates.forEach((coords) => {
            const googleMapsPaths = coords.map((coord) => {
              return coord.map((subCoord) => {
                return { lat: subCoord[1], lng: subCoord[0] };
              });
            });

            const googleMapsPolygon = new Polygon({
              map: this.map,
              paths: googleMapsPaths,
              fillOpacity: 0,
              strokeWeight: 3,
              strokeColor: "#FF0000",
              zIndex: 10,
              clickable: false,
              visible: visible,
            });

            // add custom props
            googleMapsPolygon.area_id = areaId;
            this.polygonObjects.push(googleMapsPolygon);
          });
        }

        // update the area of interest to say we've fetched the boundary
        for (let key in this.areasOfInterest) {
          for (let j = 0; j < this.areasOfInterest[key].length; j++) {
            if (this.areasOfInterest[key][j].id === areaId) {
              this.areasOfInterest[key][j].boundary_fetched = true;
              break;
            }
          }
        }
      }
    },
    makeAreaOfInterestVisible(areaID) {
      this.polygonObjects.forEach((polygon) => {
        if (polygon.area_id === areaID) {
          toRaw(polygon).setVisible(true);
        }
      });
    },
    hideAreaOfInterest(areaID) {
      this.polygonObjects.forEach((polygon) => {
        if (polygon.area_id === areaID) {
          toRaw(polygon).setVisible(false);
        }
      });
    },
    zoomToArea(areaID) {
      const bounds = new google.maps.LatLngBounds();
      this.polygonObjects.forEach((polygon) => {
        if (polygon.area_id === areaID) {
          polygon.getPath().forEach((latLng) => {
            bounds.extend(latLng);
          });
        }
      });
      this.map.fitBounds(bounds);
    },
    zoomToDefaultView() {
      // get the clients default view
      const defaultZoom = this.$store.getters.customClientConfig.default_zoom;
      const defaultLat = this.$store.getters.customClientConfig.default_lat;
      const defaultLng = this.$store.getters.customClientConfig.default_lng;

      // if they have them ALL, set the map up to that
      if (defaultZoom && defaultLat && defaultLng) {
        if (!isNaN(defaultLat) && !isNaN(defaultLng)) {
          this.map.setZoom(defaultZoom);
          this.map.setCenter({
            lat: parseFloat(defaultLat),
            lng: parseFloat(defaultLng),
          });
        } else {
          console.error("Invalid coordinates for default view");
        }
      }
    },
    getAreaValue(areaID) {
      const area = this.polygonsWithDataset.features.find(
        (f) => f.properties.area_id === areaID,
      );

      return {
        name: area.properties.area_name,
        value: area.properties.value,
      };
    },
    higlightAreaOnMap(areaID, strokeWeight) {
      this.map.data.forEach((feature) => {
        if (feature.getProperty("area_id") === areaID) {
          this.map.data.overrideStyle(feature, {
            strokeWeight: strokeWeight,
          });
        }
      });
    },
  },
  watch: {
    isTabOpened: "handlePanelChange",
    indicatorDetailsDialogue(val) {
      if (
        val &&
        !this.fetchingQuintiles &&
        this.mapQuintiles.q1_min === "loading"
      ) {
        this.getDataCount();
      }
    },
  },
};
</script>
<style scope>
/* make the expansion panel titles smaller */
.expansionTitlePanel,
.v-expansion-panel--active
  > .v-expansion-panel-title:not(.v-expansion-panel-title--static) {
  min-height: 10px !important;
  max-height: 47px !important;
}

/* hide google map text buttons from the right cornter */
#discoveryToolMapCanvas .gm-style-cc,
#discoveryToolMapCanvas .gmnoprint {
  display: none;
}
#discoveryToolMapCanvas .v-expansion-panel-text__wrapper {
  padding: 0;
}

.map-container {
  transition: height 0.3s ease-in-out;
}

/* custom styling for disabled expansion panel */
.v-expansion-panel--disabled .v-expansion-panel-title {
  color: black !important;
  background-color: #c9c9c9 !important;
}
.v-expansion-panel--disabled
  .v-expansion-panel-title
  .v-expansion-panel-title__overlay {
  opacity: 0 !important;
}

.indInfoHeader {
  margin: 2px;
  padding: 0;
}

.indInfoText {
  margin: 2px;
  margin-bottom: 20px !important;
  padding: 0;
}
</style>

<style>
.loadingQuintiles {
  margin-bottom: 3px;
}

/* make the space between the rows smaller */
.loadingQuintiles .v-skeleton-loader__text {
  margin: 5px;
}
</style>
