import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/Login.vue";
import MapPage from "../views/Map.vue";
import ReportsPage from "../views/Reports.vue";
import SiteConfig from "../views/SiteConfig.vue";
import UserEditor from "../views/UserEditor.vue";
import ClientConfigOptions from "../views/ClientConfigOptions.vue";
import ClientOnBoarder from "../views/ClientManager.vue";
import GeometryImporter from "../views/GeometryImporter.vue";
import DataManager from "../views/DataManager.vue";
import UserManager from "../views/UserManager.vue";
import UserModuleGroupManager from "../views/UserModuleGroupManager.vue";
import ThemesManager from "../views/ThemesManager.vue";
import ReportManager from "../views/ReportManager.vue";
import ReportThemes from "../views/ReportThemes.vue";
import CustomAreaManager from "../views/CustomAreaManager.vue";
import DashboardPage from "../views/Dashboard.vue";
import UserAdmin from "../views/UserAdmin.vue";
import ServicesManager from "../views/ServicesManager.vue";
import ExtraPages from "../views/ExtraPages.vue";
import ViewCustomPages from "@/views/ViewCustomPages.vue";
import CustomData from "@/views/CustomData.vue";
import GroupSettings from "@/views/GroupSettings.vue";
import DiscoveryTool from "@/views/DiscoveryTool.vue";
import DiscoveryToolMapsPage from "@/views/DiscoveryToolMapsPage.vue";
import ChartSandbox from "@/views/ChartSandbox.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: MapPage,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/map",
    name: "Map",
    component: MapPage,
  },
  {
    path: "/reports",
    name: "Reports",
    component: ReportsPage,
  },
  {
    path: "/site-config",
    name: "Site Config",
    component: SiteConfig,
  },
  {
    path: "/user-editor",
    name: "User profile editor",
    component: UserEditor,
  },
  {
    path: "/custom-client-config-options",
    name: "Custom Client Config Options",
    component: ClientConfigOptions,
  },
  {
    path: "/client-manager",
    name: "Client Manager",
    component: ClientOnBoarder,
  },
  {
    path: "/group-settings",
    name: "Group Settings",
    component: GroupSettings,
  },
  {
    path: "/geometry-importer",
    name: "Geometry Importer",
    component: GeometryImporter,
  },
  {
    path: "/data-manager",
    name: "Data Manager",
    component: DataManager,
  },
  {
    path: "/user-manager",
    name: "User Manager",
    component: UserManager,
  },
  {
    path: "/user-module-group-manager",
    name: "User Module Group Manager",
    component: UserModuleGroupManager,
  },
  {
    path: "/custom-area-manager",
    name: "Custom Area Manager",
    component: CustomAreaManager,
  },
  {
    path: "/manage-services",
    name: "Manage Services",
    component: ServicesManager,
  },
  {
    path: "/report-manager",
    name: "Report Manager",
    component: ReportManager,
  },
  {
    path: "/report-themes",
    name: "Report Themes",
    component: ReportThemes,
  },
  {
    path: "/themes",
    name: "Themes Manager",
    component: ThemesManager,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardPage,
  },
  {
    path: "/user-admin",
    name: "UserAdmin",
    component: UserAdmin,
  },
  {
    path: "/extra-pages",
    name: "Extra Pages",
    component: ExtraPages,
  },
  {
    path: "/view-custom-pages",
    name: "View Custom Pages",
    component: ViewCustomPages,
  },
  {
    path: "/custom-data",
    name: "Custom Data",
    component: CustomData,
  },
  {
    path: "/discovery-tool",
    name: "Discovery Tool",
    component: DiscoveryTool,
    children: [
      {
        path: "map/:parentAreaIDs",
        name: "Discovery Tool Map",
        component: DiscoveryToolMapsPage,
      },
    ],
  },
];

//extra dev only routes
if (process.env.NODE_ENV !== "production") {
  routes.push({
    path: "/chart-sandbox",
    name: "Chart Sandbox",
    component: ChartSandbox,
  });
}

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
