/* global google */
import { Loader } from "@googlemaps/js-api-loader";

// Function to initialize the map
async function initMap(center, zoom, elementID = "mapCanvas") {
  try {
    const { Map } = await google.maps.importLibrary("maps");
    const map = new Map(document.getElementById(elementID), {
      center: center,
      zoom: zoom,
      mapId: "fee9d62b462803e" + elementID,
      mapTypeControl: false, // Disable satellite view
      fullscreenControl: false, // Disable fullscreen view
      streetViewControl: false, // Disable street view
      zoomControl: false, // Enable zoom control
      disableDefaultUI: true,
      keyboardShortcuts: false,
      gestureHandling: false,
      options: {
        gestureHandling: "greedy",
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_CENTER,
        },
      },
    });
    return map;
  } catch (error) {
    console.error("Error initializing Google Maps:", error);
  }
}

// Function to load the map
function loadGoogleMaps(center, zoom, mapElementID) {
  const key = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
  const loader = new Loader({
    apiKey: key,
    version: "weekly",
  });

  // Return the Promise from loader.load()
  return loader.load().then(() => {
    // Return the Promise from initMap
    return initMap(center, zoom, mapElementID);
  });
}

export { loadGoogleMaps };
